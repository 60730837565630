const GenCodeUtils={
    form: {
        id: "",
        tbName: "",
        modelName: "",
        modelCaption: "",
        javaPath: "",
        vuePath: "",
        cardCol:"1",
        billFlag:'0',
        billModel:''
    },
    //表单验证规则
    rules: {
        tbName: [
            { required: true, message: "请选择表名", trigger: "change" }
        ],
        modelName: [
            { required: true, message: "请输入模块名称", trigger: "blur" }
        ],
        javaPath: [
            { required: true, message: "请输入后台代码存放路径", trigger: "blur" }
        ],
        vuePath: [
            { required: true, message: "请输入前代码存放路径", trigger: "blur" }
        ]
    },
    //除了第一个表格，其它表格的toolbar都差不多，提取出来
    buildEx1GridToolBarDetailParam(options){
        return {
            toolBar: [
                {
                    id: "selfAdd",
                    title: "增加",
                    type: "primary",
                    icon: "el-icon-zoom-in",
                    clickEvent: options.clickEvent,
                    param: options.detailType
                }
            ],
            canAdd: false,//关闭明细表格自带的新增按钮，因为添加了自己的toolBar，在toolBar中有自己的新增按钮
            canUp:true,
            canDown:true,
            canPage: false,
            queryParam: {
                detailType: options.detailType,//明细的类型由明细表名称来区别
                table: options.detailType,
                id: options.id
            },
            modelMethod: "/genCode/detail?t="+Math.random()*1000
        }
    },
    //根据子表动态显示的tab表格，用计算属性来定义他们的detailParam，根据选取的子表表名来区分
    cardDetailFieldsDetailParam(tbName,_this){
        return this.buildEx1GridToolBarDetailParam({clickEvent:"cardDetailAddHandler",detailType:tbName,id: _this.form.id});
    },
    //列表查询字段表格、列表显示字段表格、卡片显示字段表格的detailParam差不多，这里提取出来了。
    build234GridToolBarDetailParam(detailParamInst, clickEventName, detailType) {
        let details=this.buildEx1GridToolBarDetailParam({clickEvent:clickEventName,detailType:detailType,id: ""});
        detailParamInst.detailParam = details;
    },
    //对于:ref动态绑定的ref，如果用$refs得到的是一个数组
    getInstByName(name,_this){
        // let inst = _this[name];
        // if(!inst){
        //     inst=_this['refMap'].get(name);
        // }
        // return inst;
        return _this['refMap'].get(name);
    },
    //格式转化，例如DB_SYS_CORP变为 DbSysCorp
    getJavaNameByDbName(dataBaseName){
        let tbNameArr=dataBaseName.split("_");
        let modelName="";
        tbNameArr.forEach(function(item,index,arr){
            if(index!=0){
                item=item.toLowerCase();
                item=item.slice(0, 1).toUpperCase() + item.slice(1);
                modelName+=item;
            }
        });
        modelName=modelName.trim();
        return modelName;
    }
}
export default GenCodeUtils;